<template>
  <div>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
    <b-row>
      <div class="black-col" :class="{'col-md-8':window.innerWidth<=760 || window.innerWidth>1024,'col-md-12':window.innerWidth>760 && window.innerWidth<=1024}" >
        <b-card  title="Montant total généré" class="black-card" >
          <div class="round-element white">
            <img src='@/assets/images/dollar.svg' style="width:9px !important;" class="icon" width="20" />
          </div>
         
          <b-row  v-if="window.innerWidth<=760 || window.innerWidth>1024">
            <div class="col-md-6 col-nb"  >
              <b-dropdown pill variant="outline-secondary" class="top-button down top-button1" :text="currentDateFilter.text">
                <b-dropdown-item v-if="currentDateFilter.value!='week'" @click="selectDateFilter('week','This week')">This week</b-dropdown-item>
                <!-- <b-dropdown-item v-if="currentDateFilter.value!='month'" @click="selectDateFilter('month','This month')">This month</b-dropdown-item> -->
                <b-dropdown-item v-if="currentDateFilter.value!='year'" @click="selectDateFilter('year','This year')">This year</b-dropdown-item>
              </b-dropdown>

                <h2 class="big-nb contenudesk"><span class="sign">€</span> {{ totalComission.toLocaleString() }} </h2>
            </div>
            <div class="col-md-6 black">
              <!-- <div class="green-top black" v-if="totalFiltered>0"><img src='@/assets/images/arrow.svg' class="arrow" width="70" /> {{totalFiltered}}</div> -->
              <VueApexCharts v-if="blackGraphLoading==false" type="bar" :options="chartOptions" :series="series_black"/>
              <h2 class="big-nb contenumob"><span class="sign">€</span> {{ totalComission.toLocaleString() }} </h2>
            </div>
          </b-row>
          <div v-else>
            <b-dropdown pill variant="outline-secondary" class="top-button down top-button1" :text="currentDateFilter.text">
                <b-dropdown-item v-if="currentDateFilter.value!='week'" @click="selectDateFilter('week','This week')">This week</b-dropdown-item>
                <!-- <b-dropdown-item v-if="currentDateFilter.value!='month'" @click="selectDateFilter('month','This month')">This month</b-dropdown-item> -->
                <b-dropdown-item v-if="currentDateFilter.value!='year'" @click="selectDateFilter('year','This year')">This year</b-dropdown-item>
              </b-dropdown>

            <b-row>
              
              <div class="col-nb col-md-6">
           
                  <h2 class="big-nb"><span class="sign">€</span> {{ totalComission.toLocaleString() }} </h2>
              </div>
              <div class="black col-md-6">
                <!-- <div class="green-top black" v-if="totalFiltered>0"><img src='@/assets/images/arrow.svg' class="arrow" width="70" /> {{totalFiltered}}</div> -->
                <VueApexCharts v-if="blackGraphLoading==false" type="bar" :options="chartOptions" :series="series_black"/>
                
              </div>
            
            
            </b-row>
          </div>
        </b-card>
     
      </div>

      

      <b-card title="Mon montant" class="col-md-4" v-if="window.innerWidth<768 || window.innerWidth>1024">
       
          <div class="round-element blue">
            <img src='@/assets/images/profile.svg' class="icon" width="70" />
          </div>
          <b-button class="top-button black top-button2" @click="sendPaymentRequest">Demander le payout</b-button>
            <div class="col-nb numcontent">
              <h2 class="big-nb"><span class="sign">€</span>{{ (totalComission - totalPayouts).toLocaleString()  }} <span class="little-waiting">({{ totalComissionWaiting }}€ en attente)</span></h2> 
              
            </div>
          
        </b-card>
        

    </b-row>

  

    <b-row>
      <b-card title="Mon montant" class="col-md-6 "  v-if="window.innerWidth>760 && window.innerWidth<=1024">
       
       <div class="round-element blue">
         <img src='@/assets/images/profile.svg' class="icon" width="70" />
       </div>
       <b-button class="top-button black top-button2" @click="sendPaymentRequest">Demander le payout</b-button>
         <div class="col-nb numcontent">
         <h2 class="big-nb"><span class="sign">€</span>{{ (totalComission - totalPayouts).toLocaleString()  }}<span class="little-waiting">({{ totalComissionWaiting }}€ en attente)</span></h2> 
       </div>
       
     </b-card>
     
      <div :class="{'col-md-4':window.innerWidth<=760 || window.innerWidth>1024,'col-md-6':window.innerWidth>760 && window.innerWidth<=1024}"  v-if="comissions.length>0">
        <b-card  title="Best sales">

         
          <div class="round-element green">
            <img src='@/assets/images/stats.svg' class="icon" width="70" />
          </div>
          <!-- <span class="grey">Avec un petit texte ici</span> -->
          <br /><br />
          <template v-if="bestSellers.length!=0 && bestSellersLoading==false" >
            <VueApexCharts  v-if="bestSellers.length>=3" type="area" :options="options" :series="series"/>
            <VueApexCharts  v-else type="bar" :options="options_small" :series="series" />

          </template>
         
          <div v-if="bestSellers.length==0">
            <span class="grey">Aucune vente enregistrée</span>
          </div>

          <table v-else class="table left-align">
            <tr>
              <th>Products</th>
              <th>Price</th>
              <th>Sales</th>
            </tr>
            <tr v-for="(bestSeller, index) in bestSellers.slice(0, 3)">
              <td><span class="pastille" :class="{ purple: index == 0, pink: index == 1, blue: index == 2 }"></span> {{ bestSeller.name }}</td>
              <td class="price">{{ bestSeller.amount }} €</td>
              <td><div class="green-top"><img src='@/assets/images/arrow.svg' class="arrow" width="70" /> {{ bestSeller.nb_sales }}</div></td>
            </tr>
            
          </table>
        </b-card>
        
      </div>
     
        <b-card  @mousedown="startDrag" @mousemove="onDrag" @mouseup="endDrag" @mouseleave="endDrag" @touchstart="startDrag" @touchmove="onDrag" @touchend="endDrag" v-if="currentToggle=='sales'" :class="{'col-md-8': comissions.length>0 && (window.innerWidth<=760 || window.innerWidth>1024),'col-md-12': comissions.length==0 || (window.innerWidth>760 && window.innerWidth<=1024)}" >
          <!-- <span class="grey">Avec un petit texte ici</span> -->
          <div class="toggle-select-titles">
            <div class="toggle-title active">Produits en ligne</div>
            <div class="toggle-title" @click="currentToggle='products'">Produits en attente</div>
          </div>
          <h4 class="card-title">Nom & Description Produit</h4>
          <!-- <div class="toggle-select">
            <div class="toggle active"></div>
            <div class="toggle" @click="currentToggle='products'"></div>
          </div> -->
          <br /><br />
          <div class="round-element right clickable" :class="{grey:editingGlasses==false,gray:editingGlasses==true}" @click="editingGlasses=!editingGlasses">
            <img src='@/assets/images/edit2.svg' class="icon" width="70" />
          </div>
          <div class="round-element green">
            <img src='@/assets/images/ok.svg' class="icon" width="70" />
          </div>
          
            <div v-if="glasses.length==0">
              <br /><br />
              <center>
                Vous n'avez aucun produit publié pour le moment.
              </center>

              <br /><br /><br /><br />
            </div>
            <div v-else>

              <div class="contenumob"><br /></div>

            
          
              <!-- table -->  
              <vue-good-table
                v-if="editingGlasses==false"
                class="left-align"
                :columns="window.innerWidth>760 ? columns2 : columns2mob"
                :rows="glasses"
                :pagination-options="{
                  enabled: true,
                  perPage:pageLength
                }"
              
              >
              
                  
                <template v-if="hideTable==false"
                  slot="table-row"
                  slot-scope="props"
                  class="table-row"
                >
                  <span
                    v-if="props.column.field === 'name'"
                    class="text-nowrap"
                  >
                    <!-- <b-avatar
                      :src="props.row.photos[0]"
                      class="mx-1"
                    /> -->
                    <span class="text-nowrap">{{ props.row.name }}</span>
                  </span>
                  <span v-else-if="props.column.field === 'stock'">
                    <b-badge v-if="props.row.stock>100" variant="light-success">
                      {{ props.row.stock }}
                    </b-badge>
                    <b-badge v-else-if="props.row.stock==0" variant="light-danger">
                      {{ props.row.stock }}
                    </b-badge>
                    <b-badge v-else variant="light-warning">
                      {{ props.row.stock }}
                    </b-badge>
                  </span>
                  <!-- Column: Action -->
                  <span v-else-if="props.column.field === 'price'">
                    {{ props.formattedRow[props.column.field] }}€
                  </span>
                  <span v-else-if="props.column.field === 'description'">
                    <span class="product-description" v-if="props.formattedRow[props.column.field]!=null && props.formattedRow[props.column.field]!=''">
                      <template v-if="displayMore[props.index]==false">
                        {{ props.formattedRow[props.column.field].substring(0,150) }}
                        <span v-if="props.formattedRow[props.column.field].length>150">...
                    
                        <a href="#" @click.prevent="() => displayDescription(props.index)">Voir plus</a></span>
                      </template>
                      <template v-else>
                        {{ props.formattedRow[props.column.field] }}
                    
                        <a href="#" @click.prevent="() => hideDescription(props.index)">Réduire</a>
                      </template>
                      
                    </span>
                    <span v-else>
                      En attente
                    </span>
                  
                  </span>
        
                  <span v-else-if="props.column.field === 'id'">
                    <RenderGlassComponent :glass="props.row" />
                  </span>
                  
                  <span v-else-if="props.column.field === 'action'">
                    <span>
                      <b-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                      >
                        <template v-slot:button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="text-body align-middle mr-25"
                          />
                        </template>
                        <b-dropdown-item  @click="$router.push({name:'editGlass', params: { id: props.row.id }})">
                          <feather-icon
                            icon="Edit2Icon"
                            class="mr-50"
                          />
                          <span>Suggérer une modification</span>
                        </b-dropdown-item>
                      
                      </b-dropdown>
                    </span>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap ">
                        Page 1 de
                      </span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['3','5','10','50']"
                        class="mx-1"
                        @input="(value)=>props.perPageChanged({currentPerPage:value})"
                      />
                      <span class="text-nowrap"> sur {{ props.total }} résultats </span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="totalRows"
                        v-model="currentPage"
                        :per-page="pageLength"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="(value)=>props.pageChanged({currentPage:value})"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                  <b-modal
                    id="modal-danger"
                    cancel-variant="outline-secondary"
                    cancel-title="Non"
                    ok-variant="danger"
                    ok-title="Oui"
                    @ok="deleteGlass(selectedItem)"
                    modal-class="modal-danger"
                    centered
                    title="Êtes-vous sûr ?"
                  >
                    <b-card-text>
                      Attention, cette action est irréversible ! Êtes-vous sûr de vouloir supprimer cette lunette ?
                    </b-card-text>
                  </b-modal>
                </template>
              </vue-good-table>
              
              <!-- table -->
              <vue-good-table
                v-else
                class="left-align"
                 :columns="window.innerWidth>760 ? columns2 : columns2mob"
                :rows="glasses"
                :pagination-options="{
                  enabled: true,
                  perPage:pageLength
                }"
                @on-row-click="(params) =>  $router.push({name:'editGlass', params: { id: params.row.id }})"
              >
                <template
                  
                
                  slot="table-row"
                  slot-scope="props"
                  class="table-row"
                >
                  <span
                    v-if="props.column.field === 'name'"
                    class="text-nowrap"
                  >
                    <!-- <b-avatar
                      :src="props.row.photos[0]"
                      class="mx-1"
                    /> -->
                    
                    <span class="text-nowrap">{{ props.row.name }}</span> <img src='@/assets/images/crayon.svg' class="icon-edit" width="70" />
                  </span>
                  <span v-else-if="props.column.field === 'stock'">
                    <b-badge v-if="props.row.stock>100" variant="light-success">
                      {{ props.row.stock }}
                    </b-badge>
                    <b-badge v-else-if="props.row.stock==0" variant="light-danger">
                      {{ props.row.stock }}
                    </b-badge>
                    <b-badge v-else variant="light-warning">
                      {{ props.row.stock }}
                    </b-badge>
                  </span>
                  <!-- Column: Action -->
                  <span v-else-if="props.column.field === 'price'">
                    {{ props.formattedRow[props.column.field] }}€
                  </span>
                  <span v-else-if="props.column.field === 'description'">
                    <span v-if="props.formattedRow[props.column.field]" class="description-product">
                        
                          {{ props.formattedRow[props.column.field].substring(0,30) }}...
                      
                    </span>
                  
                  </span>
                  <span v-else-if="props.column.field === 'id'">
                    
                    <RenderGlassComponent :glass="props.row" />
                  </span>
                 
                  
                  <span v-else-if="props.column.field === 'action'">
                    <span>
                      <b-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                      >
                        <template v-slot:button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="text-body align-middle mr-25"
                          />
                        </template>
                        <b-dropdown-item  @click="$router.push({name:'editGlass', params: { id: props.row.id }})">
                          <feather-icon
                            icon="Edit2Icon"
                            class="mr-50"
                          />
                          <span>Suggérer une modification</span>
                        </b-dropdown-item>
                      
                      </b-dropdown>
                    </span>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap ">
                        Page 1 de
                      </span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['3','5','10','50']"
                        class="mx-1"
                        @input="(value)=>props.perPageChanged({currentPerPage:value})"
                      />
                      <span class="text-nowrap"> sur {{ props.total }} résultats </span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="totalRows"
                        v-model="currentPage"
                        :per-page="pageLength"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="(value)=>props.pageChanged({currentPage:value})"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                  <b-modal
                    id="modal-danger"
                    cancel-variant="outline-secondary"
                    cancel-title="Non"
                    ok-variant="danger"
                    ok-title="Oui"
                    @ok="deleteGlass(selectedItem)"
                    modal-class="modal-danger"
                    centered
                    title="Êtes-vous sûr ?"
                  >
                    <b-card-text>
                      Attention, cette action est irréversible ! Êtes-vous sûr de vouloir supprimer cette lunette ?
                    </b-card-text>
                  </b-modal>
                </template>
              </vue-good-table>

            </div>
        
        </b-card>

        <b-card  @mousedown="startDrag" @mousemove="onDrag" @mouseup="endDrag" @mouseleave="endDrag" @touchstart="startDrag" @touchmove="onDrag" @touchend="endDrag" :class="{'col-md-8': comissions.length>0 ,'col-md-12': comissions.length==0}"  v-else>

          <div class="toggle-select-titles">
            <div class="toggle-title" @click="currentToggle='sales'">Produits en ligne</div>
            <div class="toggle-title active" >Produits en attente</div>
          </div>
          <h4 class="card-title">Sample validation</h4>
          <!-- <div class="toggle-select">
            <div class="toggle"  @click="currentToggle='sales'"></div>
            <div class="toggle active"></div>
          </div> -->
          <!-- <div class="round-element grey right">
            <img src='@/assets/images/edit2.svg' class="icon" width="70" />
          </div> -->
          <div class="round-element orange">
            <img src='@/assets/images/sablier.svg' class="icon" width="70" />
          </div>
          <!-- <span class="grey">Avec un petit texte ici</span> -->
          <br /><br />

          <div v-if="editSuggestsPending.length==0">
            <span class="grey">Aucune suggestion en attente</span>
          </div>

          <table v-else class="table left-align">
            <tr>
              <th>Products</th>
              <th>Price</th>
              <th>Date</th>
              <th class="text-center">Render</th>
            
            </tr>
            <tr v-for="edit_suggest in editSuggestsPending">
              <td>{{edit_suggest.name}}</td>
              <td>{{edit_suggest.price}}€</td>
              <td>
                {{edit_suggest.created_at}}
              </td>
              <td class="text-center">
                <RenderGlassComponent :glass="edit_suggest.glass" />
              </td>
              
            </tr>
            
          
          </table>
        </b-card>
     
    </b-row>

  
</b-overlay>
<!-- <OnboardingComponent :isVisible="true" /> -->
</div>
</template>

<script>
import {BModal, BTable, BTableSimple, BTableLite, BTd, BTbody, BTr, BTh, BThead, BPagination, BRow, BButton, VBModal,BOverlay, BCard, BCardText, BAvatar, BBadge, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest, Woocommerce,Auth} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueApexCharts from 'vue-apexcharts'
import OnboardingComponent from '../onboarding/OnboardingComponent.vue'
import RenderGlass from '@/components/RenderGlass.vue'



export default {
  components: {
    BCard,
    BTable,
    BTd,
    BTr,
    BTbody,
    BThead,
    BTh,
    BPagination,
    BTableSimple,
    BCardText,
    VueGoodTable,
    RenderGlassComponent:RenderGlass,
    BAvatar,
    OnboardingComponent ,
    BBadge,
    BPagination,
    BRow,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    VueApexCharts,
    BDropdownItem,
    BModal,
    BOverlay,
    BButton
  },
  data() {
    return {
      glasses:[],
      totalComissionWaiting:0,
      window:window,
      loading:true,
      editingGlasses:false,
      currentToggle:'sales',
      bestSellersLoading:true,
      selectedItem:null,
      currentDateFilter:{text:'This week',value:'week'},

      columns2mob: [
       
        {
          label: 'Products',
          field: 'name',
          tdClass:"products-col"
        },
        
        {
          label: 'Price',
          field: 'price',
        },
        {
          label: 'Render',
          field: 'id',
          thClass:"text-center",
          tdClass:"text-center"
        },
        // {
        //   label: 'Action',
        //   field: 'action',
        // },
       
      ],
      columns2: [
       
        {
          label: 'Products',
          field: 'name',
          tdClass:"products-col"
        },
        {
          label: 'Description',
          field: 'description',
           tdClass:"desc-col"
        },
        {
          label: 'Price',
          field: 'price',
        },
        {
          label: 'Render',
          field: 'id',
           thClass:"text-center",
          tdClass:"text-center"
        },
        // {
        //   label: 'Action',
        //   field: 'action',
        // },
       
      ],
      rows: [],
      searchTerm: '',
      orders:[],
      pageLength: 3,
      dir: false,
      payouts:null,
      totalPayouts:0,
      activePayment:false,
      loading:true,
      glasses:[],
      totalRows:0,
      currentPage:1,
      columns: [
        {
          label: 'Montant',
          field: 'amount',
        },
        {
          label: 'Effectué le',
          field: 'created',
        }
        
      ],
      selectedItem:null,
      
      rows: [],
      searchTerm: '',

      chartOptions: {
          chart: {
            
            type: 'bar',
            toolbar: {
              show: false,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: false,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false
                
              },
          },
        }, 
          zoom: {
            enabled: false,
          },
          tooltip: {
            enabled: false, // Désactive la bulle au survol
          },
          states: {
            hover: {
                filter: {
                    type: 'none',
                }
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                }
            },
        },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 3,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
              colors: {
                // ICI pour activer couleur
                ranges: [{
                  from: 0,
                  to: 100000,
                  color: '#E3E3E3'
                }],
                  
              },
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + "€";
            },
            offsetY: -20,
            style: {
              fontWeight: 'normal',
              fontSize: '12px',
              colors: ["#fff"]
            }
          },
              
          xaxis: {
            categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            position: 'bottom',
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            
            tooltip: {
              enabled: false,
            },
            labels: {
              style: {
                colors: '#ffffff',
              }
              
             
            }
          },
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
             
            }
          
          },
          grid: {
            show: false,
            borderColor: '#ffffff',
            strokeDashArray: 0,
            position: 'back',
            xaxis: {
              lines: {
                show: false
              }
            },
            yaxis: {
              lines: {
                show: false
              }
            }
          }
            
        },

      optionsBlack: {
        chart: {
          id: 'vuechart-example',
          toolbar: {
              show: false,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: false,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false
                
              },
          },
        },
        stroke: {
          width: 1,
          curve: 'straight',
          colors: ['#ffffff']
        },
        fill: {
          type: 'color',
          color:"green",
        },
        xaxis: {
         
          lines: {
            show: false,
          },
          categories: ["Mon", "Tue", "Wed","Thu", "Fri", "Sat", "Sun"]
        },
        yaxis: {
          lines: {
            show: false,
          }
        },
        dataLabels: {
          style: {
            colors: ['#000000']
          }
        },
        zoom: {
            enabled: false,
          },
      },
      options_small: {
          chart: {
            
            type: 'bar',
            toolbar: {
              show: false,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: false,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false
                
              },
            },
          },
          tooltip: {
            enabled: false, // Désactive la bulle au survol
          },
          states: {
            hover: {
                filter: {
                    type: 'none',
                }
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                }
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 3,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
              colors: {
                ranges: [{
                  from: 0,
                  to: 100000,
                  color: '#E3E3E3'
                }],
                  
              },
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: -20,
            style: {
              fontWeight: 'normal',
              fontSize: '12px',
              colors: ["#000"]
            }
          },
              
          xaxis: {
            categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            position: 'bottom',
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            
            tooltip: {
              enabled: false,
            }
          },
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
             
            }
          
          },
          grid: {
            show: false,
            borderColor: '#ffffff',
            strokeDashArray: 0,
            position: 'back',
            xaxis: {
              lines: {
                show: false
              }
            },
            yaxis: {
              lines: {
                show: false
              }
            }
          }
            
        },
      options: {
        chart: {
          id: 'vuechart-example',
          toolbar: {
              show: false,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: false,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false
                
              },
          },
        },
        zoom: {
            enabled: false,
          },
        stroke: {
          width: 2,
          curve: 'smooth',
          colors: ['#29C770']
        },
        dataLabels: {
          enabled: false,
          formatter: function (val, opts) {
            return val; // Valeur du point à afficher
          },
          
         
        },
        
        markers: {
              size: 4, // Taille des markers
              fillOpacity: 1,
               // Utilise les couleurs définies dans la série ou les options globales
              discrete: [
                { seriesIndex: 0, dataPointIndex: 0, fillColor: '#8984FC', strokeColor: '#8984FC', size: 4 }, // Produit A
                { seriesIndex: 0, dataPointIndex: 1, fillColor: '#FA1D9C', strokeColor: '#FA1D9C', size: 4 }, // Produit B
                { seriesIndex: 0, dataPointIndex: 2, fillColor: '#398FDA', strokeColor: '#398FDA', size: 4 }, // Produit C
              ],
        },
        tooltip: {
          enabled: false, // Désactive la bulle au survol
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            colorStops: [
              {
                offset: 0,
                color: '#29C770',
                opacity: 1
              },
              {
                offset: 100,
                color: '#ffffff',
                opacity: 1
              }
            ]
          }
        },
        xaxis: {
          categories: [1991, 1992, 1993],
          labels: {
            show: false,
            
          },
          axisBorder: {
              show: false,
            
          },
          axisTicks: {
              show: false,
        
          },
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        
        grid: {
          show: false,
          borderColor: '#ffffff',
          strokeDashArray: 0,
          position: 'back',
          xaxis: {
            lines: {
              show: false
            }
          },
          yaxis: {
            lines: {
              show: false
            }
          }
        }
      },
      series: [{
        name: 'Nombre de ventes',
        data: [
        { x: 'Produit A', y: 30, fillColor: '#29C770' }, // Point avec couleur verte
        { x: 'Produit B', y: 40, fillColor: '#ff0000' }, // Point avec couleur rouge
        { x: 'Produit C', y: 45, fillColor: '#0000ff' }  // Point avec couleur bleue
        ]
       
      }],
      series_black: [{
        name: 'Montant généré',
        data: [30, 40, 45, 50, 49, 60, 70]
      }],
      startX: 0,
      isDragging: false,
      totalComission:0,
      displayMore:[],
      editSuggestsGlobal:[],
      editSuggestsPending:[],
      blackGraphLoading:true,
      editSuggestsOk:[],
      comissions:[],
      bestSellers:[],
      hideTable:false,
      totalFiltered:0

    }
  },
  methods:{
    startDrag(event) {
        this.isDragging = true;
        this.startX = event.touches ? event.touches[0].clientX : event.clientX;
    },
    onDrag(event) {
        if (!this.isDragging) return;
        let currentX = event.touches ? event.touches[0].clientX : event.clientX;
        let diff = this.startX - currentX;
        if (diff > 50) {
            this.nextSlide();
            
            this.isDragging = false;
        } else if (diff < -50) {
            this.nextSlide();
            this.isDragging = false;
        }
    },
    nextSlide(){
      if(this.currentToggle=='sales')
      {
        this.currentToggle='products'
      }
      else
      {
        this.currentToggle='sales'
      }
    },
    endDrag() {
        this.isDragging = false;
    },
    displayDescription(index)
    {
      this.displayMore[index]=true
      this.hideTable=true
      setTimeout(() => {
        this.hideTable=false
      }, 10);
    },
    selectDateFilter(value,text)
    {
      this.currentDateFilter.text=text
      this.currentDateFilter.value=value
      console.log('ok1')
      this.filterComissionsByDate()
      console.log('ok2')
    },
    hideDescription(index)
    {
      this.displayMore[index]=false
      this.hideTable=true
      setTimeout(() => {
        this.hideTable=false
      }, 10);
    },
    calculateBestSellers() {
        this.bestSellersLoading=true
        this.bestSellers=[]

        for(let i=0;i<this.comissions.length;i++)
        {
          let exists=false
          for(let j=0;j<this.bestSellers.length;j++)
          {
            if(this.bestSellers[j].id==this.comissions[i].glass.id)
            {
              exists=true
              console.log("exists")
              console.log(this.bestSellers[j])

              //existe deja, on augmente le nombre de ventes et le montant
              this.bestSellers[j].nb_sales=this.bestSellers[j].nb_sales+1
              this.bestSellers[j].amount=this.bestSellers[j].amount+this.comissions[i].amount
            }
          }
          // n'existe pas, on ajoute le nouvel objet
          if(exists==false)
          {
            let obj={
              id:this.comissions[i].glass.id,
              name:this.comissions[i].glass.name,
              nb_sales:1,
              amount:this.comissions[i].amount
            }
            this.bestSellers.push(obj)
          }
         
        }

        this.bestSellers.sort((a, b) => b.nb_sales - a.nb_sales);
        let categories_new=[]
        let datas_new=[]
        for(let i=0;i<3;i++)
        {
          if(this.bestSellers[i])
          {
            let obj={ x: 'Produit A', y: this.bestSellers[i].nb_sales, fillColor: '#ffffff' }
            datas_new.push(this.bestSellers[i].nb_sales)
            categories_new.push(this.bestSellers[i].name)
          }
          
          
        }
        this.series[0].data=datas_new
        this.bestSellersLoading=false
        
        this.options.xaxis.categories=categories_new
        this.options_small.xaxis.categories=categories_new
 
      
      },
    async deleteGlass(id)
    {
      this.loading=true
      try {
        await APIRequest.delete('glasses',id)
        
        let glasses=await APIRequest.list_with_request('glasses','?active=1&limit=1000&creator_owner='+localStorage.id_user_senseye_auth)
  
        this.glasses=glasses[0]
        this.displayMore = this.glasses.map(() => false);

        this.totalRows-=1
        this.loading=false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'La lunette a été supprimée !',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.loading=false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Une erreur est survenue.',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
        
      }
      
    },
    filterComissionsByDate(){
      this.blackGraphLoading=true
      this.totalFiltered=0
      let date_end=new Date()
      let date_begin
      if(this.currentDateFilter.value=='week')
      {
        let date = new Date()
        date.setDate(date.getDate() - 7)
        date_begin = date

        // Calculate all days of the week until today and put them in a table
        var daysOfWeek = []
        var valuesOfWeek = []
        for (let i = 0; i < 7; i++) {

          let tempDate = new Date(date_begin)
          tempDate.setDate(tempDate.getDate() + i)
          daysOfWeek.push(tempDate.toLocaleDateString('fr-FR', { weekday: 'short' }))
          valuesOfWeek.push(0)
        }
        
        this.chartOptions.xaxis.categories=daysOfWeek

        var datesOfWeek = []
        for (let i = 0; i < 7; i++) {
          let tempDate = new Date(date_begin)
          tempDate.setDate(tempDate.getDate() + i)
          datesOfWeek.push(tempDate.toLocaleDateString('us-US'))
        }

    
        
      

      }
      if(this.currentDateFilter.value=='month')
      {
        let date = new Date()
        date.setDate(date.getDate() - 30) 
        date_begin = date

        // Calculate all days of the month until today and put them in a table
        var daysOfMonth = []
        var valuesOfMonth = []
        for (let i = 0; i < 30; i++) {
          let tempDate = new Date(date_begin)
          tempDate.setDate(tempDate.getDate() + i)
          daysOfMonth.push(tempDate.toLocaleDateString('fr-FR', { day: 'numeric', month: 'short' }))
          valuesOfMonth.push(0)
        }
        
        this.chartOptions.xaxis.categories = daysOfMonth

        var datesOfMonth = []
        for (let i = 0; i < 30; i++) {
          let tempDate = new Date(date_begin)
          tempDate.setDate(tempDate.getDate() + i)
          datesOfMonth.push(tempDate.toLocaleDateString('us-US'))
        }


      }
      if(this.currentDateFilter.value=='year')
      {
        let date = new Date()
        date.setDate(date.getDate() - 365) 
        date_begin = date

        // Calculate all months of the year until today and put them in a table
        var monthsOfYear = []
        var valuesOfYear = []
        for (let i = 0; i < 12; i++) {
          let tempDate = new Date(date_begin)
          tempDate.setMonth(tempDate.getMonth() + i)
          monthsOfYear.push(tempDate.toLocaleDateString('fr-FR', { month: 'short', year: '2-digit' }))
          valuesOfYear.push(0)
        }
        
        this.chartOptions.xaxis.categories = monthsOfYear

        var datesOfYear = []
        for (let i = 0; i < 12; i++) {
          let tempDate = new Date(date_begin)
          tempDate.setMonth(tempDate.getMonth() + i)
          datesOfYear.push(tempDate.toLocaleDateString('us-US'))
        }
      }


      console.log('ok11')
          
      for(let i=0;i<this.comissions.length;i++)
      {
        if(this.comissions[i].date!=null)
        {
        
          let [d, m, y] = this.comissions[i].date.split(/-|\//); // splits "26-02-2012" or "26/02/2012"
          let date = new Date(y, m - 1, d);
          

          // On verifie si c'est compris entre les deux dates



          if(date.getTime()>=date_begin.getTime() && date.getTime()<=date_end.getTime())
          {


            // Si oui on ajoute le total de la comission
        
           

            if(this.currentDateFilter.value=='week')
            {
              for(let j=0;j<daysOfWeek.length;j++)
              {
                if(date.toLocaleDateString('fr-FR', { weekday: 'short' })==daysOfWeek[j])
                {
                  valuesOfWeek[j]+=this.comissions[i].amount
                  this.totalFiltered+=this.comissions[i].amount
                }
              }
              
            }
            if(this.currentDateFilter.value=='month')
            {
              for(let j=0;j<daysOfMonth.length;j++)
              {
                if(date.toLocaleDateString('fr-FR', { day: 'numeric', month: 'short' })==daysOfMonth[j])
                {
                  valuesOfMonth[j]+=this.comissions[i].amount
                  this.totalFiltered+=this.comissions[i].amount
                }
              }
            }
            if(this.currentDateFilter.value=='year')
            {
              for(let j=0;j<monthsOfYear.length;j++)
              {
                if(date.toLocaleDateString('fr-FR', { month: 'short', year: '2-digit' })==monthsOfYear[j])
                {
                  valuesOfYear[j]+=this.comissions[i].amount
                  this.totalFiltered+=this.comissions[i].amount
                }
              }
            }
          }

        }
      }

      console.log('ok111')

      if(this.currentDateFilter.value=='week')
        {
          this.series_black[0].data=valuesOfWeek
        }
        if(this.currentDateFilter.value=='month')
        {
          this.series_black[0].data=valuesOfMonth
        }
        if(this.currentDateFilter.value=='year')
        {
          this.series_black[0].data=valuesOfYear
        }

        setTimeout(() => {
          this.blackGraphLoading=false
        }, 10);
      //this.blackGraphLoading=false
    },
    convertTimestamp(ts){
      let date=new Date(ts * 1000)

      
      return date.toLocaleDateString('fr-FR')
    },
    async calculateRealCom()
    {
      let totalCom=0
      let totalComWaiting=0
      
      for(let i=0;i<this.comissions.length;i++)
      {
        if(this.comissions[i].date)
        {
          let currentDate = new Date();

          // Add 7 days to the current date
          let beforeDate = new Date(currentDate);
          beforeDate.setDate(currentDate.getDate() - 14);

     
          let [day, month, year] = this.comissions[i].date.split('/'); // Split the date string
          let timestamp = new Date(`${year}-${month}-${day}`); // Convert to timestamp

          if(timestamp.getTime()<beforeDate.getTime())
          {
            totalCom+=this.comissions[i].amount
          }
          else
          {
            console.log('waiting')
            console.log(this.comissions[i].date)
            totalComWaiting+=this.comissions[i].amount
          }

        }
        else 
        {
          totalCom+=this.comissions[i].amount
        }
        
      }
      this.totalComission=totalCom
      this.totalComissionWaiting=totalComWaiting
    },
    async sendPaymentRequest()
    {

      let params={
        user:{id:localStorage.id_user_senseye_auth},
        amount:this.totalComission-this.totalPayouts,
        payout_method:'Virement bancaire'
      }
      await APIRequest.create('payout-requests',params)

      await this.getPayoutRequests()
      //await APIRequest.send_payment_request(localStorage.id_user_senseye_auth,this.totalComission-this.totalPayouts)
      this.$toast({
          component: ToastificationContent,
          props: {
              title: 'La demande de paiement a été envoyée !',
              icon: 'CheckIcon',
              variant: 'success',
          },
      })

   
    }
  },
  watch:{
    
   
   
  },
  async mounted()
  {
    let edits_suggest = await APIRequest.list('edit-suggests',null,null,1000)


    this.editSuggestsGlobal=edits_suggest[0]
    for(let i=0;i<edits_suggest[0].length;i++)
    {
      if(edits_suggest[0][i].status=='pending')
      {
        this.editSuggestsPending.push(edits_suggest[0][i])
      }
      else
      {
        this.editSuggestsOk.push(edits_suggest[0][i])
      }
    }

    let scans=await APIRequest.get_comissions(localStorage.id_user_senseye_auth)

    console.log('okkk')

    this.comissions=scans.data

    console.log(this.comissions)
    this.filterComissionsByDate()
    this.calculateBestSellers()
    await this.calculateRealCom()

    //this.totalComission=scans.total

    let user_data=await Auth.get_user_with_id(localStorage.id_user_senseye_auth)
   

    if(user_data.stripe_id)
    {
      this.activePayment=true
      let payouts=await APIRequest.get_payouts_stripe(user_data.stripe_id)

      this.totalPayouts=0
      this.payouts=payouts.data
      for(let i=0;i<payouts.data.length;i++)
      {
      this.totalPayouts=this.totalPayouts+payouts.data[i].amount/100
      }
    }

    let glasses=await APIRequest.list_with_request('glasses','?active=1&limit=1000&creator_owner='+localStorage.id_user_senseye_auth)
    this.glasses=glasses[0]
    this.displayMore = this.glasses.map(() => false);
  
    this.totalRows=this.glasses.length
    this.loading=false
      

   

    // on get tous les produits assignés

    // let scans=await APIRequest.list_with_request('glasses', '?creator_owner='+localStorage.id_user_senseye_auth)
    // //let scans=await APIRequest.list('glasses')
    // this.glasses=scans[0]

    // // pour chaque produit on get les orders
    // this.orders=[]
    // for(let i=0;i<this.glasses.length;i++)
    // {
      
    //   let request='?model='+this.glasses[i].id+'&limit=1000'
    //   let orders=await APIRequest.list_with_request('order-items', request)

     
    //   orders=orders[0]

    //   let orders_final=[]

    //    // on vérifie si elle existe deja
    //   for(let j=0;j<orders.length;j++)
    //   {
    //     let exists=false

    //     for(let p=0;p<this.orders.length;p++)
    //     {
    //       if(orders[j].id==this.orders[p].id)
    //       {
    //         exists=true
    //       }
    //     }
    //     if(exists==false)
    //     {
    //       orders_final.push(orders[j])
    //       this.orders.push(orders[j])
    //     }
    //   }

    //   //this.orders.push(orders_final)


    // }

    // console.log('ok part 1')

    // // pour chaque order on recup la/les lignes concernées par un produit
    


    // for(let i=0;i<this.orders.length;i++)
    // {

    //   console.log(this.orders[i])
    //     // on calcule la comission pour la ligne et on l'ajoute au total
    //       for(let j=0;j<this.glasses.length;j++)
    //       {
    //         if(this.glasses[j].id==this.orders[i].glass.id)
    //         {
    //           if(this.glasses[j].comission)
    //           {
              
    //             this.totalComission=this.totalComission+((this.orders[i].amount/100)*this.glasses[j].comission)
    //           }
    //         }
       
          
    //   }
    // }
      
    // for(let i=0;i<this.orders.length;i++)
    // {
    //   console.log('test order')
    //   console.log(this.orders)
    //   let order_detail=await APIRequest.detail('orders', this.orders[i].id)
    //   if(order_detail.order_items)

    //   {
    //     for(let p=0;p<order_detail.order_items.length;p++)
    //     {
    //       for(let j=0;j<this.glasses.length;j++)
    //       {
    //         if(this.glasses[j].id==order_detail.order_items[p].glass.id)
    //         {

    //           // on calcule la comission pour la ligne et on l'ajoute au total

    //           if(order_detail.order_items[p].glass.comission)
    //           {
    //             this.totalComission=this.totalComission+((order_detail.order_items[p].amount/100)*order_detail.order_items[p].glass.comission)
    //           }
              
    //         }
    //       }
    //     }
    //   }
      

    // }



    // on recup tous les payouts effectués

    // on calcule le solde total du montant du


    this.loading=false
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';

.top-button2
{
  
    left:170px;
  
  
}
.top-button1
{
  
    left:220px;
  
  
}
.price
{
  white-space: nowrap;
}
.table-row:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.table-row {
  cursor: pointer;
}
.icon-edit
{
  width:12px;
  margin-left: 5px;
}
.product-description a 
{
  text-decoration: underline;
}
.desc-col
{
  width:50%;
}
.numcontent
{
  height:90% !important;
}
.products-col
{
  width:20%;
}

td.text-center,th.text-center
{
  text-align: center !important;
}
.render 
{
  margin-left:auto;
  margin-right:auto;
}
@media screen and (min-width:1200px) and (max-width:1500px) {
  .numcontent
  {
    height:70% !important;
  }
 .top-button.black
 {
  top:0;
  margin-top:10px;
  position: relative;
  left:0;
 }
 th,td {
        font-size: 13px;
        padding:5px 10px !important;
    }
}
</style>
