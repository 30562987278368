<template>
    <div v-if="isVisible" class="modal-overlay">
        <div class="logo contenudesk"><img src="@/assets/images/logo_white.png" /></div>
        <div class="modal-container">
            <!-- <button class="close-button" @click="closeModal">X</button>
             -->
            <div class="header-content-top">
                <button v-show="showLeftArrow" class="arrow left-arrow" @click="scrollLeft">&#8249;</button>
                <button v-show="showRightArrow" class="arrow right-arrow" @click="scrollRight">&#8250;</button>
            
                <div class="header">
                
                    <div class="steps-container">
                        <div ref="stepsContainer"  class="steps">
                            <div class="step active">
                                <div class="line"></div>
                                <div class="line2"></div>
                                <div class="step-title">
                                    <div class="number"><span>1</span></div>
                                    <div class="text">Contact</div>
                                </div>
                            </div>
                            <div class="step" :class="{active:currentStep>=2}">
                                <div class="line"></div>
                                <div class="line2"></div>
                                <div class="step-title">
                                    <div class="number"><span>2</span></div>
                                    <div class="text">Personal Info</div>
                                </div>
                            </div>
                            <div class="step"  :class="{active:currentStep>=3}">
                                <div class="line"></div>
                                <div class="line2"></div>
                                <div class="step-title">
                                    <div class="number"><span>3</span></div>
                                    <div class="text">Adress</div>
                                </div>
                            </div>
                            <div class="step"  :class="{active:currentStep>=4}">
                                <div class="line"></div>
                                <div class="line2"></div>
                                <div class="step-title">
                                    <div class="number"><span>4</span></div>
                                    <div class="text">Biography</div>
                                </div>
                            </div>
                            <!-- <div class="step"  :class="{active:currentStep>=5}">
                                <div class="line"></div>
                                <div class="line2"></div>
                                <div class="step-title">
                                    <div class="number"><span>5</span></div>
                                    <div class="text">Royalty Distr.</div>
                                </div>
                            </div> -->
                            <div class="step"  :class="{active:currentStep>=5}">
                                <div class="line"></div>
                                <div class="line2"></div>
                                <div class="step-title">
                                    <div class="number"><span>5</span></div>
                                    <div class="text">Form</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
           
            <template v-if="currentStep==1" >
                <div class="title-top">
                    Enter Your Contact Details
                    <div class="grey">
                        For Smooth Communication And Updates.
                    </div>
                </div>
                <div class="form">
                    <form @submit.prevent="nextStep()">
                        <input type="text" @input="inputChange()" v-model="step1.email" required class="text-input" placeholder="E-mail" />
                        <input type="password" @input="inputChange()"  v-model="step1.password"  required class="text-input" placeholder="Nouveau mot de passe" />
                        <input type="password" @input="inputChange()" v-model="step1.password_repeat"  required class="text-input" placeholder="Confirmation du mot de passe" />
                        <div class="buttons">
                            <div @click="prevStep()" class="button previous">Previous</div>
                            <button  type="submit" class="button submit" :class="{disabled:requiredFieldsOk==false}">Next</button>
                        </div>
                    </form>
                </div>

            </template>

            <template v-if="currentStep==2" >
                <div class="title-top">
                    Enter Your Personal Details 
                    <div class="grey">
                        Your Name Helps Us Personalize Your Experience.
                    </div>
                </div>
                <div class="form">
                    <form @submit.prevent="nextStep()">
                        <input type="text" @input="inputChange()" v-model="step2.brand_name" required class="text-input" placeholder="Artist's name" />
                        <input type="text" @input="inputChange()"  v-model="step2.name"  required class="text-input" placeholder="Nom" />
                        <input type="text" @input="inputChange()" v-model="step2.surname"  required class="text-input" placeholder="Prénom" />
                        <div class="buttons">
                            <div @click="prevStep()" class="button previous">Previous</div>
                            <button  type="submit" class="button submit" :class="{disabled:requiredFieldsOk==false}">Next</button>
                        </div>
                    </form>
                </div>

            </template>


            <template v-if="currentStep==3" >
                <div class="title-top">
                    Enter The Address
                    <div class="grey">
                        Where You Would Like Your Samples To Be Shipped.
                    </div>
                </div>
                <div class="form">
                    <form @submit.prevent="nextStep()">
                        <div class="form-line">
                            <input type="text" @input="inputChange()" v-model="step3.phone" required class="text-input" placeholder="Téléphone" />
                            <input type="text" @input="inputChange()"  v-model="step3.address1"  required class="text-input" placeholder="Adresse" />
                        </div>
                        <div class="form-line">
                            <input type="text" @input="inputChange()" v-model="step3.postcode" required class="text-input" placeholder="Code postal" />
                            <input type="text" @input="inputChange()"  v-model="step3.city"  required class="text-input" placeholder="Ville" />
                        </div>
                        <div class="form-line">
                            <input type="text" @input="inputChange()" v-model="step3.country" required class="text-input" placeholder="Pays" />
                            <input type="text" @input="inputChange()"  v-model="step3.address2"  class="text-input" placeholder="Additional information" />
                        </div>
                        <div class="buttons">
                            <div @click="prevStep()" class="button previous">Previous</div>
                            <button  type="submit" class="button submit" :class="{disabled:requiredFieldsOk==false}">Next</button>
                        </div>
                    </form>
                </div>

            </template>


            <template v-if="currentStep==4" >
                <div class="title-top">
                    Share A Brief Bio 
                    <div class="grey">
                        To Tell Others About Yourself And Your Expertise.
                    </div>
                </div>
                <div class="form">
                    <form @submit.prevent="nextStep()">
                        <textarea type="text" @input="inputChange()" v-model="step4.bio"  class="text-input" placeholder="Biography" />
                        
                        <div class="buttons">
                            <div @click="prevStep()" class="button previous">Previous</div>
                            <button  type="submit" class="button submit">Next</button>
                        </div>
                    </form>
                </div>

            </template>

            <template v-if="currentStep==8" >
                <div class="title-top">
                    Royalty Distribution 
                    <div class="grey">
                        Your Earnings Will Be Sent Directly To This Account.
                    </div>
                </div>
                <div class="form">
                    <form @submit.prevent="nextStep()">
                        <input type="text" @input="inputChange()" v-model="step5.account_name"  class="text-input" placeholder="Nom du titulaire du compte" />
                        <input type="text" @input="inputChange()"  v-model="step5.iban"   class="text-input" placeholder="Numéro de compte (IBAN)" />
                        
                        <div class="buttons">
                            <div @click="prevStep()" class="button previous">Previous</div>
                            <button  type="submit" class="button submit">Next</button>
                        </div>
                    </form>
                </div>

            </template>

            <template v-if="currentStep==5" >
                <div class="title-top big">
                    {{$user.name}}, Welcome to your Dashboard.
                    <div class="grey">
                        You’re Now Ready to Get Started. Success Begins Here!
                    </div>
                </div>
                <div class="buttons">
                    <div @click="closeModal()" class="button previous">My Dashboard</div>
                    <button  type="submit" class="button submit">+ Submit a design</button>
                </div>
              

            </template>

            <div v-if="currentStep>=3 && currentStep<5" class="skip" @click="currentStep=5">Skip onboarding</div>
            
        </div>
    </div>
</template>

<script>
  import {APIRequest,Auth} from '@simb2s/senseye-sdk'
export default {
    name: 'FullScreenModal',
    props: {
        isVisible: {
            type: Boolean,
            required: true
        }
    },
    data() {
      return {
        showLeftArrow: false,
        showRightArrow: false,
        requiredFieldsOk:false,
        currentStep:this.$user.password_changed ? 2 : 1,
        address:null,
        user:null,
        step1:{
            email:'',
            password:'',
            password_repeat:''
        },
        step2:{
            brand_name:'',
            name:'',
            surname:''
        },
        step3:{
            phone:'',
            address1:'',
            address2:'',
            postcode:'',
            city:'',
            country:'',
        },
        step4:{
            bio:'',
            
        },
        step5:{
            account_name:'',
            iban:'',
        },
      }
    },
    methods: {
        updateArrows() {
            const container = this.$refs.stepsContainer;
            const scrollLeft = container.scrollLeft;
            const scrollWidth = container.scrollWidth;
            const clientWidth = container.clientWidth;
            console.log(scrollLeft)
            this.showLeftArrow = scrollLeft > 0;
            this.showRightArrow = scrollLeft + clientWidth < scrollWidth;
        },
        scrollLeft() {
            const container = this.$refs.stepsContainer;
            container.scrollBy({ left: -container.offsetWidth, behavior: "smooth" });
            setTimeout(() => this.updateArrows(), 300);
        },
        scrollRight() {
            const container = this.$refs.stepsContainer;
            container.scrollBy({ left: container.offsetWidth, behavior: "smooth" });
            setTimeout(() => this.updateArrows(), 300);
        },
        inputChange()
        {
            this.requiredFieldsOk=false
            if(this.currentStep==1)
            {
                if(this.step1.email!="" && this.step1.password!="" && this.step1.password_repeat!="")
                {
                    this.requiredFieldsOk=true
                }
            }
            if(this.currentStep==2)
            {
                if(this.step2.brand_name!="" && this.step2.name!="" && this.step2.surname!="")
                {
                    this.requiredFieldsOk=true
                }
            }
            if(this.currentStep==3)
            {
                if(this.step3.phone!="" && this.step3.address1!="" && this.step3.postcode!="" && this.step3.city!="" && this.step3.country!="")
                {
                    this.requiredFieldsOk=true
                }
            }
            if(this.currentStep==4)
            {
                this.requiredFieldsOk=true
                if(this.step4.bio!="")
                {
                    this.requiredFieldsOk=true
                }
            }
            if(this.currentStep==5)
            {
                this.requiredFieldsOk=true
                if(this.step5.account_name!="" && this.step5.iban!="")
                {
                    this.requiredFieldsOk=true
                }
            }
        },
        closeModal() {

            this.$emit('close');
            this.$user.onboarding_finished=true
            setTimeout(() => {
                this.$router.push('/')
            }, 500);
           
            
        },
        async nextStep()
        {
            

            this.currentStep+=1
            this.inputChange()

            if(this.currentStep==2)
            {
                
                let datas = {
                    username:this.step1.email,
                    password:this.step1.password,
                   
                }
                await Auth.update_account(localStorage.id_user_senseye_auth, datas)
                this.requiredFieldsOk=true
                
            }

            if(this.currentStep==3)
            {
                if(this.step1.password!=this.step1.password_repeat)
                {
                    alert('Passwords do not match')
                    this.requiredFieldsOk=false
                    return
                }
                
                let datas = {
                    brand_name:this.step2.brand_name,
                    name:this.step2.name,
                    surname:this.step2.surname,
                    onboarding_finished:true
                }
                await Auth.update_account(localStorage.id_user_senseye_auth, datas)

                this.requiredFieldsOk=true

                
            }
            if(this.currentStep==4)
            {
                let datas = {
                    address1:this.step3.address1,
                    address2:this.step3.address2,
                    city:this.step3.city,
                    postcode:this.step3.postcode,
                    country:this.step3.country,
                    phone:this.step3.phone,
                    name:"default"
                }
                if(this.address)
                {
                    await APIRequest.update('addresses',this.address.id,datas)
                }
                else
                {
                    await APIRequest.create('addresses',datas,localStorage.id_user_senseye_auth)
                }
                this.requiredFieldsOk=true
            }

            if(this.currentStep==5)
            {
                
                let datas = {
                
                    biography:this.step4.bio
                }
                await Auth.update_account(localStorage.id_user_senseye_auth, datas)
                this.requiredFieldsOk=true
                
            }
            
            if(this.currentStep==8)
            {
                this.closeModal()
            }
        },
        prevStep()
        {
            if(this.currentStep>1)
            {
                this.currentStep-=1
                this.inputChange()
            }
         
        }
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.updateArrows);
    },
    async mounted() {
        this.updateArrows();
        window.addEventListener("resize", this.updateArrows);
        let user=await Auth.get_user_with_id(localStorage.id_user_senseye_auth)

        this.step1.email=user.email
        this.step2.brand_name=user.brand_name
        this.step2.name=user.name
        this.step2.surname=user.surname
        this.step4.bio=user.biography
        this.user=user
        let addresses=await APIRequest.list('addresses',localStorage.id_user_senseye_auth)
      
        if(addresses[0].length>0)
        {
            this.step3.address1=addresses[0][0].address1
            this.step3.address2=addresses[0][0].address2
            this.step3.city=addresses[0][0].city
            this.step3.country=addresses[0][0].country
            this.step3.phone=addresses[0][0].phone
            this.step3.postcode=addresses[0][0].postcode


        }
        this.address=addresses[0][0]

    }
}
</script>

<style scoped>
.header-content-top
{
    position: relative;
}
.form-line input
{
    width:calc(50% - 10px);
}
.form-line
{
    display:flex;
    justify-content: space-between;
}
.skip
{
    margin-left:auto;
    margin-right:auto;
    border-bottom:1px solid #fff;
    display:block;
    width:fit-content;
    font-size:18px;
    cursor: pointer;
    margin-top:50px;
}
.disabled
{
    opacity: 0.5;
}
.buttons {
    margin-top:50px;
    display: flex;
    justify-content: center;
}
.logo img 
{
    width:150px;
}
.logo
{
    position:absolute;
    top:30px;
    left:30px;
}
.modal-overlay input,.modal-overlay textarea {
   color:#fff;
background: rgba(57, 57, 57, 0.1) 0% 0% no-repeat padding-box;
border: 0.5px solid rgba(255, 255, 255, 0.5);
border-radius: 3px;
opacity: 1;
font-size: 16px;
margin-top:15px;
backdrop-filter: blur(30px);
-webkit-backdrop-filter: blur(30px);
padding:10px 20px;
}
.step.active .line2
{
    width:100%;
}
.step .line2 
{
    width:0%;
    height: 2px;  
    background-color: #fff;
    margin-bottom: 20px;
    transition : all 0.5s;
}
.step .line 
{
    width:100%;
    height: 1px;
    background-color: #fff;
   
}
.form form
{
    margin-top: 50px;
    display:flex;
    flex-direction: column;
}
.steps
{
    max-width: 100%;
    display:flex;
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
    margin: 0 auto;
}
.arrow {
    background:transparent;
    border: none;
    color: #fff;
    font-size: 12px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background 0.3s;
    z-index: 10;
}



.steps-container {
    overflow-x: auto;
    display: flex;
    flex: 1;
    scroll-behavior: smooth; /* Ajoute un défilement fluide */
}
.arrow.left-arrow {
    position: absolute;
    left: -30px;
    top: 25px;
}

.arrow.right-arrow {
    position: absolute;
    right: -30px;
    top: 25px;
}
.step
{
    width:20%;
    padding:5px;
    font-size: 12px;
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  
}
.title-top
{
    animation: fadein 0.5s ease-in;
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    animation: fadein 0.5s ease-in;
    right: 0;
    bottom: 0;
    background-color:rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    
    align-items: center;
    z-index: 9999;
}
.title-top.big 
{
    font-size: 60px;
}
.title-top
{
    margin-top:50px;
    line-height: 1;
    font-size:45px;
    text-align: center;
}
.step-title .number span 
{
    position: absolute;
    line-height: 1;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
}
.step.active .number
{
    background-color: #fff;
    color:#000;
}
.step-title .number
{
    position: relative;
    width:25px;
    margin-right:10px;
    font-size: 12px;
    height:25px;
    border-radius:50%;
    border:1px solid #fff;
}
.step-title
{
    align-items: center;
    display: flex;
}
.title-top .grey {
    color:rgba(153, 153, 153, 1);
}
.modal-container {
   
    padding: 20px;
    width:80%;
    max-width: 900px;
    color:#fff;
    background:transparent;
}

.button.previous:hover 
{
    background:#fff;
    color:#000;
}
.button.previous
{
    color:#fff;

    background: transparent;
    border: 1px solid rgba(255, 255, 255, 1);
}
.button.submit:hover
{
    border:1px solid #000;
    background:#000;
    color:#fff;
}
.button.submit
{
    border: 1px solid rgba(255, 255, 255, 1);
background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
color:#000;

    border: 1px solid rgba(255, 255, 255, 1);
}
input::placeholder, textarea::placeholder
{
  color:#fff;
  font-style: italic;
}
.button
{
    transition: all 0.3s;
    margin-left:10px;
    margin-right:10px;
    min-width: 200px;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    font-size:18px;
    padding:10px 20px;
}
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}
.header {
    display: flex;
    overflow: hidden;
    align-items: center;
    position: relative;
}
@media screen and (max-width: 768px) {
   

    .arrow.left-arrow {
   
    left: -20px;
    top: 25px;
}

.arrow.right-arrow {
   
    right: -20px;
    top: 25px;
}
   
      .header {
        overflow: hidden;
      }
      .step {
        min-width:120px;
      }
      .modal-container
    {
        padding-top:50px;
        padding-bottom:100px;
        overflow: auto;
        max-height: 80vh;
        width:100%;
      }
      input,.button
      {
        font-size:13px;
      }
      .buttons .button
      {
        margin-bottom:10px;
      }
      .buttons 
      {
        flex-direction: column;
      }
      .title-top
      {
        font-size:20px;
      }
      .title-top.big
      {
        font-size:23px;
      }
      .skip
      {
        font-size:13px;
      }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
   
   .header {
    overflow: hidden;
   }
   .step {
     min-width:120px;
   }
   .modal-container {
     padding: 20px;
     width:80%;
   }
   input,.button
   {
     font-size:15px;
   }
   .buttons .button
   {
     margin-bottom:10px;
   }
   .buttons 
   {
     flex-direction: column;
   }
   .title-top
   {
     font-size:28px;
   }
   .title-top.big
   {
     font-size:30px;
   }
   .skip
   {
     font-size:18px;
   }
}


@media screen and (min-width: 1024px) and (max-width: 1400px) {
   
   .header {
    overflow: hidden;
   }
   .step {
     min-width:120px;
   }
   .modal-container {
     padding: 20px;
     width:80%;
   }
   input,.button
   {
     font-size:16px;
   }
  
 
   .title-top
   {
     font-size:35px;
   }
   .title-top.big
   {
     font-size:38px;
   }
   .skip
   {
     font-size:20px;
   }
}
</style>